import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {} from '@progress/kendo-angular-common';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ClientSelectionAdminComponent } from './Client-Selection-admin/client-selection.component';
import { ClientSelectionLandingComponent } from './Client-Selection-Landing/client-selection-landing.component';
import { ClientSelectionComponent } from './Client-Selection/client-selection.component';

@NgModule({
  imports: [
    CommonModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    DropDownsModule,
    ButtonsModule,
    GridModule,
    FormsModule,
    DialogsModule
  ],
  declarations: [ClientSelectionComponent, ClientSelectionAdminComponent, ClientSelectionLandingComponent]
})
export class SharedComponentsModule {}
