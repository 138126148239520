@if ((loading | async) === false) {
<div class="container">
  <img class="logo-image" src="./assets/MRI_Logo_RGB_Small.png" alt="Logo" />
  <form>
    <kendo-formfield showErrors="always">
      <kendo-textbox
        #clientIdTextBox
        name="clientId"
        placeholder="Client ID"
        [(ngModel)]="clientId"
        [showSuccessIcon]="success"
        [showErrorIcon]="fail"
        (valueChange)="onValueChange()"
      >
      </kendo-textbox>
      <kendo-formerror *ngIf="form.controls.clientId.errors?.invalidClientId"> Invalid Client Id</kendo-formerror>
      <kendo-formerror *ngIf="form.controls.clientId.errors?.errorOccured">
        Error occured. Please try again</kendo-formerror
      >
    </kendo-formfield>

    @if(!this.user.defaultClientId){
    <div class="default-client-checkbox">
      <input
        [disabled]="clientId === ''"
        type="checkbox"
        #checked
        kendoCheckBox
        [checked]="checkboxChecked"
        (change)="onChange($event)"
      />
      <kendo-label class="k-checkbox-label checkbox-label" [for]="checked" text="Set as default client"></kendo-label>
    </div>
    }@else {
    <div class="default-client-actions">
      <span class="default-client-text">Default client: {{ this.user.defaultClientId }}</span>
      <span (click)="clearDefault()" class="clear-default-client-id">Clear default</span>
    </div>
    }

    <div class="actions">
      <a class="help" href="https://www.mrisoftware.com/services/contact-support/"> Need help? </a>
      <button kendoButton (click)="Continue()">Continue</button>
    </div>
  </form>
</div>

}
