import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClientService,
  PendoSharedService,
  UserApiService,
  UserInfo,
  UserService
} from '@mri-platform/ag-shared/services';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { BehaviorSubject, filter, mergeMap, of, switchMap, take, tap } from 'rxjs';

@Component({
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mri-ag-shared-client-selection-admin',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSelectionAdminComponent implements OnInit {
  public checkboxChecked = false;
  public changeDefaultClientId = false;
  public mustChangeClientId = '';
  public user: UserInfo = {
    userId: '',
    defaultClientId: '',
    email: '',
    isGlobalAdmin: false
  };

  @ViewChild('clientIdTextBox') clientIdTextBox!: TextBoxComponent;
  loading = new BehaviorSubject<boolean>(false);
  success = false;
  fail = false;
  clientId = '';
  form!: FormGroup;
  urlClientId = '';

  constructor(
    private clientService: ClientService,
    private router: Router,
    private userApiService: UserApiService,
    private route: ActivatedRoute,
    private pendoService: PendoSharedService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => !!clientId),
        // prevent multiple initializations
        take(1),
        switchMap(() => this.pendoService.initPendo())
      )
      .subscribe();

    this.urlClientId = this.route.snapshot.queryParamMap.get('mri_client_id')?.toUpperCase() ?? '';

    this.mustChangeClientId = this.route.snapshot.queryParamMap.get('change_client_id') ?? 'false';
  }

  createForm() {
    this.form = new FormGroup({
      clientId: new FormControl(null)
    });
  }

  ngOnInit() {
    this.userService
      .getUserInfo()
      .pipe(
        filter(user => !!user && user.userId !== this.user.userId),
        tap(user => {
          this.user = user;
          this.updateUrlClientId();
          this.cd.detectChanges();
          if (this.urlClientId && this.urlClientId !== '') {
            this.handleUrlClientId();
          }
        })
      )
      .subscribe();

    this.createForm();
    this.form.controls.clientId.valueChanges.subscribe(() => {
      this.form.controls.clientId.setErrors(null);
      this.fail = false;
    });
  }

  onValueChange() {
    this.form.controls.clientId.setErrors(null);
  }

  handleUrlClientId() {
    if (this.urlClientId) {
      this.loading.next(true);
      this.clientService
        .getClient(this.urlClientId)
        .pipe(
          mergeMap(client => {
            if ('id' in client && client.id.toUpperCase() === this.urlClientId) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if ((window as any).pendo) {
                this.pendoService.updatePendoAgentClientId();
              }
              this.clientService.setClientId(this.urlClientId);
              this.clientService.setClientObject(client);
              this.clientService.setClientIdSet();
              this.success = true;
              return this.userApiService.updateLoggedInUserCache(this.urlClientId);
            } else {
              this.loading.next(false);
              this.fail = true;
              this.form.controls.clientId.setErrors({ invalidClientId: true });
              this.clientIdTextBox.focus();
              return of(null);
            }
          })
        )
        .subscribe(data => {
          if (data !== null && data === 200) {
            if (this.clientService.getFromAdmin()) {
              this.router.navigate(['/admin']);
            } else {
              this.router.navigate(['../']);
            }
          } else {
            if (!this.fail) {
              this.loading.next(false);
              this.form.controls.clientId.setErrors({ errorOccured: true });
              this.clientIdTextBox.focus();
            }
          }
        });
    }
  }

  updateUrlClientId() {
    if (this.urlClientId === '') {
      this.urlClientId = this.user?.defaultClientId ?? '';
      if (this.mustChangeClientId === 'true') {
        this.urlClientId = '';
      }
    }
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.checkboxChecked = true;
      this.changeDefaultClientId = true;
    }
  }

  clearDefault() {
    this.user.defaultClientId = '';
    this.userService.setUser(this.user);
    this.userService.updateDefaultClientId(this.user.userId, null).subscribe();
    this.checkboxChecked = false;
  }

  Continue() {
    this.form.controls.clientId.setErrors(null);
    this.fail = false;
    this.clientService
      .getClient(this.clientId.toUpperCase())
      .pipe(
        mergeMap(client => {
          if ('id' in client && client.id.toUpperCase() === this.clientId.toUpperCase()) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).pendo) {
              this.pendoService.updatePendoAgentClientId();
            }
            this.clientService.setClientId(this.clientId.toUpperCase());
            this.clientService.setClientObject(client);
            this.clientService.setClientIdSet();
            this.success = true;
            if (this.changeDefaultClientId) {
              this.user.defaultClientId = this.clientId.toUpperCase();
              this.userService.setUser(this.user);
              this.userService.updateDefaultClientId(this.user.userId, this.user.defaultClientId);
            }
            return this.userApiService.updateLoggedInUserCache(this.clientId);
          } else {
            this.fail = true;
            this.form.controls.clientId.setErrors({ invalidClientId: true });
            this.clientIdTextBox.focus();
            return of(null);
          }
        })
      )
      .subscribe(data => {
        if (data !== null && data === 200) {
          if (this.clientService.getFromAdmin()) {
            this.router.navigate(['/admin']);
          } else {
            this.router.navigate(['../']);
          }
        } else {
          if (!this.fail) {
            this.form.controls.clientId.setErrors({ errorOccured: true });
            this.clientIdTextBox.focus();
          }
        }
      });
  }
}
