import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppUser } from '@mri-platform/ag-shared/services';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { map, Observable } from 'rxjs';
@Component({
  templateUrl: './client-selection-landing.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSelectionLandingComponent {
  isAdmin: Observable<boolean>;
  constructor(private authService: AuthService) {
    this.isAdmin = this.authService.userLoadedOfType$<AppUser>().pipe(map(user => user.isGlobalAdmin));
  }
}
