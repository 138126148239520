<ng-container kendoDialogContainer>
  @if ((loading | async) === false) { @if ( (this.clientService.getClientListLoaded() | async)) {
  <div class="container">
    <div class="selection-body">
      <img class="logo-image" src="./assets/MRI_Logo_RGB_Small.png" alt="Logo" />
      <kendo-textbox placeholder="Search" class="search" (valueChange)="search($event)"></kendo-textbox>
      <kendo-grid
        [data]="clientIds"
        [selectable]="selectableSettings"
        (cellClick)="cellClickHandler($event)"
        class="grid"
      >
        <kendo-grid-column
          field="clientId"
          title="Client Selection"
          [style]="{ cursor: 'pointer' }"
          [headerStyle]="{
            'font-size': '16px',
            'font-weight': '500',
            'line-height': '24px',
            'text-align': 'left',
            'background-color': '#fff',
            cursor: 'default'
          }"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-container">
              <div class="cell-container-client">
                <span class="client-name">{{ dataItem.clientName }}</span>
                <span class="client-id">{{ dataItem.id }}</span>
              </div>
              @if ( isDefaultClientId(dataItem.id) ) {
              <span class="default-label">Default</span>
              }
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <div class="footer">
      <div class="footer-sub-details">
        <div class="default-client-checkbox">
          <input
            [disabled]="!selectedClient || checkboxChecked || isDefaultClientId(selectedClient.id)"
            type="checkbox"
            #checked
            kendoCheckBox
            [checked]="checkboxChecked"
            (change)="onChange($event)"
          />
          <kendo-label
            class="k-checkbox-label checkbox-label"
            [for]="checked"
            text="Set as default client"
          ></kendo-label>
        </div>
        @if(this.user.defaultClientId){
        <span (click)="clearDefault()" class="clear-default-client-id">Clear default</span>
        }
      </div>
      <button [disabled]="!selectedClient" kendoButton class="continue-button" (click)="continue()">Continue</button>
    </div>
  </div>

  }@else{ @if (this.clientService.getFromAdmin() === false){
  <div id="splash-container">
    <div id="splash-small-container">
      <div id="circle"></div>
      <img id="splash-img" src="/assets/MRI_FullLogo.svg" />
      <svg
        id="splash-svg"
        width="283"
        height="283"
        viewBox="0 0 370 370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M362.5 185C362.5 282.754 283.031 362 185 362C86.9695 362 7.5 282.754 7.5 185C7.5 87.2456 86.9695 8 185 8"
          stroke="url(#paint0_linear_1714_60576)"
          stroke-opacity="0.8"
          stroke-width="15"
          stroke-linecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1714_60576"
            x1="185"
            y1="8.00001"
            x2="387.543"
            y2="146.438"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#0582C3" />
            <stop offset="1" stop-color="#0582C3" stop-opacity="0.02" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>

  } } }
</ng-container>
