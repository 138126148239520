import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Client,
  ClientService,
  HelloApiService,
  PendoSharedService,
  UserInfo,
  UserService
} from '@mri-platform/ag-shared/services';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent, SelectableMode, SelectableSettings } from '@progress/kendo-angular-grid';
import Fuse from 'fuse.js';

import { BehaviorSubject, filter, mergeMap, of, switchMap, take, tap } from 'rxjs';
@Component({
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mri-ag-shared-client-selection',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSelectionComponent {
  clientIds!: Client[];
  clientIdsOriginal!: Client[];
  fuseEnviroments!: Fuse<Client>;
  fuseOptionsEnviroments = {
    includeScore: true,
    keys: ['id', 'clientName'],
    threshold: 0.4
  };
  public checkboxOnly = false;
  public mode: SelectableMode = 'single';
  public drag = false;
  public selectableSettings!: SelectableSettings;
  private urlClientId = '';
  public selectedClient: Client | undefined;
  public checkboxChecked = false;
  public user: UserInfo = {
    userId: '',
    defaultClientId: '',
    email: '',
    isGlobalAdmin: false
  };
  public mustChangeClientId = '';
  loading = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('checked') checked!: any;

  constructor(
    public clientService: ClientService,
    private router: Router,
    private dialogservice: DialogService,
    private helloApiService: HelloApiService,
    private route: ActivatedRoute,
    private userService: UserService,
    public pendoService: PendoSharedService
  ) {
    this.urlClientId = this.route.snapshot.queryParamMap.get('mri_client_id') ?? '';
    this.mustChangeClientId = this.route.snapshot.queryParamMap.get('change_client_id') ?? 'false';

    this.userService
      .getUserInfo()
      .pipe(
        filter(user => !!user && user.isGlobalAdmin === false && user.userId !== this.user.userId),
        tap(user => (this.user = user!)),
        tap(() => this.updateUrlClientId()),
        switchMap(() => (this.urlClientId ? this.handleClientId() : this.loadClientIds()))
      )
      .subscribe();

    // When Client ID is set, initialize Pendo
    this.clientService
      .getClientId()
      .pipe(
        filter(clientId => !!clientId),
        // prevent multiple initializations
        take(1),
        switchMap(() => this.pendoService.initPendo())
      )
      .subscribe();
  }

  updateUrlClientId() {
    if (this.urlClientId === '') {
      this.urlClientId = this.user?.defaultClientId ?? '';
      if (this.mustChangeClientId === 'true') {
        this.urlClientId = '';
      }
    }
  }

  handleClientId() {
    this.loading.next(true);
    return this.clientService.getClients().pipe(
      mergeMap(clients => {
        const client = clients.find(c => c.id === this.urlClientId);
        if (client) {
          this.clientService.setClientId(this.urlClientId);
          this.clientService.setClientObject(client);
          this.clientService.setClientIdSet();
          return this.userService.updateLoggedInUserCache(this.urlClientId);
        } else {
          this.loading.next(false);
          this.loadClientIds();
          return of(null);
        }
      }),
      tap(responseCode => {
        if (responseCode === 200) {
          this.navigateToRoute();
        } else if (responseCode !== null) {
          this.showClientIdError();
        }
      })
    );
  }

  loadClientIds() {
    this.loading.next(false);
    return this.clientService.getClients().pipe(
      switchMap(clients => {
        if (!clients || clients.length === 0) {
          this.router.navigate(['./unauthorized']);
          return of(null);
        } else if (clients.length === 1) {
          this.clientService.setClientId(clients[0].id);
          this.clientService.setClientObject(clients[0]);
          this.clientService.setClientIdSet();
          return this.userService.updateLoggedInUserCache(clients[0].id);
        } else {
          this.clientIds = clients;
          this.clientIdsOriginal = clients;
          this.setSelectableSettings();
          this.fuseEnviroments = new Fuse(this.clientIdsOriginal, this.fuseOptionsEnviroments);
          this.clientService.setClientListLoaded(true);
          return of(null);
        }
      }),
      tap(data => {
        if (data === 200) {
          this.navigateToRoute();
        }
      })
    );
  }

  navigateToRoute() {
    if (this.clientService.getFromAdmin()) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['../']);
    }
  }

  showClientIdError() {
    this.dialogservice.open({
      title: 'Error',
      content: `Error setting client Id: ${this.urlClientId}. Please make sure this ID is correct and try again.`,
      actions: [{ text: 'OK', primary: true }]
    });
  }

  public setSelectableSettings(): void {
    if (this.checkboxOnly || this.mode === 'single') {
      this.drag = false;
    }

    this.selectableSettings = {
      checkboxOnly: this.checkboxOnly,
      mode: this.mode,
      drag: this.drag
    };
  }

  handleAdminClick(client: Client) {
    this.userService
      .updateLoggedInUserCache(client.id)
      .pipe(
        switchMap(() => {
          this.clientService.setClientId(client.id);
          this.clientService.setClientObject(client);
          this.clientService.setClientIdSet();
          // Only update pendo agent with selected client id if it is already initialized
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((window as any).pendo) {
            this.pendoService.updatePendoAgentClientId();
          }
          return this.helloApiService.getHello();
        }),
        tap(data => {
          if (data.isClientAdmin) {
            this.router.navigate(['/admin']);
          } else {
            this.dialogservice.open({
              title: 'Unauthorized',
              content: 'You are not a client admin for the selected client'
            });
          }
        })
      )
      .subscribe();
  }

  handleNonAdminClick(client: Client) {
    this.userService
      .updateLoggedInUserCache(client.id)
      .pipe(
        tap(data => {
          if (data !== null && data === 200) {
            this.clientService.setClientId(client.id);
            this.clientService.setClientObject(client);
            this.clientService.setClientIdSet();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).pendo) {
              this.pendoService.updatePendoAgentClientId();
            }
            this.router.navigate(['../']);
          } else {
            this.dialogservice.open({
              title: 'Error',
              content: 'Error setting user cache. Please try again',
              actions: [{ text: 'OK', primary: true }]
            });
          }
        })
      )
      .subscribe();
  }
  cellClickHandler(args: CellClickEvent) {
    this.checked.nativeElement.checked = false;
    this.checkboxChecked = this.isDefaultClientId(args.dataItem.id);
    this.selectedClient = args.dataItem;
  }

  search(term: string) {
    this.selectedClient = undefined;
    if (!term) {
      this.clientIds = this.clientIdsOriginal;
    } else {
      this.clientIds = this.fuseEnviroments.search(term).map(result => result.item);
    }
  }

  continue() {
    this.setDefaultClientId(this.user.defaultClientId);
    if (this.clientService.getFromAdmin()) {
      this.handleAdminClick(this.selectedClient!);
    } else {
      this.handleNonAdminClick(this.selectedClient!);
    }
  }

  isDefaultClientId(listId: string) {
    return this.user.defaultClientId === listId;
  }

  setDefaultClientId(clientId: string | null) {
    this.userService.setUser(this.user);
    this.userService.updateDefaultClientId(this.user.userId, clientId).subscribe();
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.checkboxChecked = true;
      this.user.defaultClientId = this.selectedClient!.id;
    }
  }

  clearDefault() {
    this.user.defaultClientId = '';
    this.userService.setUser(this.user);
    this.userService.updateDefaultClientId(this.user.userId, null).subscribe();
    this.checkboxChecked = false;
  }
}
